import React from "react";
import Layout from "../components/Layout";
import AboutUsOurBrand from "../components/AboutUsOurBrand/AboutUsOurBrand";
import AboutUsOurBrandMobile from "../components/AboutUsOurBrand/AboutUsOurBrandMobile";
import "../scss/aboutus.scss";
import AboutUs3Step from "../components/AboutUs3Step/AboutUs3Step";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";

const About = (props) => {
    const { t } = useTranslation();
    return (
        <Layout logo="white" title="About Us" description="Learn about our mission and innovative plant-based products.">
            {/* <Navbar logo="white" /> */}
            <>
                <div className="aboutus">
                    <div className="aboutusBanner">
                        <div className="aboutusBannerContent">
                            <h1>{t("aboutUsPage.title1")}</h1>
                            <h5>{t("aboutUsPage.subtitle1")}</h5>
                        </div>
                    </div>
                </div>
                <div id="idea" className="anchor">
                    <AboutUsOurBrand />
                    <AboutUsOurBrandMobile />
                </div>
                <div id="innovation" className="anchor">
                    <AboutUs3Step />
                </div>
            </>

            {/* <Footer /> */}
        </Layout>
    );
};

export default (About);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common", "navbar", "footer", "aboutUsPage"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
