import React from "react";
import {  useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import "./AboutUsOurBrand.scss";
import banner1 from "../../img/aboutusOurBrand.png";
import banner2 from "../../img/aboutus/missionbg3.png";
import mission from "../../img/aboutus/ourmission.png";
import missionthai from "../../img/aboutus/ourmissionthai.png";

const AboutUsOurBrand = (props) => {
    const { language } = useI18next();
    const { t } = useTranslation();
    return (
        <div className="aboutusourBrand">
            <img loading="lazy" src={banner1} alt="Banner 1" className="bannerImg" />
            <div style={{ whiteSpace: "pre-wrap" }} className="aboutusourBrandContent">
                <h3 className="aboutusourBrandbrandHeader">{t("aboutUsPage.ourBrand.title1")}</h3>
                <h2 className="aboutusourBrandsubHeader">{t("aboutUsPage.ourBrand.subtitle1")}</h2>
                <h3 className="aboutusourBrandsubsubHeader">{t("aboutUsPage.ourBrand.subsubtitle1")}</h3>
                <div className="aboutusourBrandContentWrapper">
                    <div className="aboutusourBrandDesc">
                        <p>
                            {t("aboutUsPage.ourBrand.content1")}
                            <b>{t("aboutUsPage.ourBrand.boldContent1")}</b>
                            {t("aboutUsPage.ourBrand.content2")}
                        </p>
                    </div>
                </div>
            </div>
            <div className="aboutusOurMission">
                <div className="aboutusOurMissionBgWrapper">
                    <img loading="lazy" src={banner2} className="banner2Img" alt="our mission" />
                </div>
                <div className="aboutusOurMissionImg">{
                // props.i18n.
                language !== "th" ? <img loading="lazy" src={mission} alt="our msision" /> : <img loading="lazy" src={missionthai} alt="our msision" />}</div>
                <div className="aboutusOurMissionDesc">
                    <h2>{t("aboutUsPage.aboutUsOurMission.title1")}</h2>
                    <p>{t("aboutUsPage.aboutUsOurMission.content1")}</p>
                    <p>{t("aboutUsPage.aboutUsOurMission.content2")}</p>
                </div>
            </div>
        </div>
    );
};

export default AboutUsOurBrand;
