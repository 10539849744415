import { StaticImage } from "gatsby-plugin-image";
import React from "react";
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby";
import bg3 from "../../img/aboutus/aboutusbg3.png";
import gluten from "../../img/aboutus/gluten1.png";
import equii from "../../img/aboutus/equii2.png";
import "./AboutUs3Step.scss";

const AboutUs3Step = () => {
    const { t } = useTranslation();

    return (
        <div className="aboutusInnovation">
            <div className="aboutusEquii anchor" id="intraplant">
                <div className="aboutusEquiiHeader">
                    <h3>{t("aboutUsPage.aboutUs3Step.title1")}</h3>
                    <h2>{t("aboutUsPage.aboutUs3Step.subtitle1")}</h2>
                    <h4>{t("aboutUsPage.aboutUs3Step.subsubtitle1")}</h4>
                    <div className="glutenImg">
                        <img loading="lazy" src={gluten} alt="Gluten" />
                    </div>
                </div>
                <div className="aboutusEquiiWrapper">
                    <div className="aboutusEquiiContainer">
                        <div className="aboutusEquiiImg">
                            <img loading="lazy" src={equii} alt="3 Step Coated" />
                        </div>
                        <div className="aboutusEquiiDesc">
                            <p>{t("aboutUsPage.aboutUs3Step.content1")}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="ThreeStepCoatingWrapper anchor" id="threestep">
                <div className="ThreeStepBgWrapper">
                    <img loading="lazy" src={bg3} alt="background" />
                </div>
                <div className="ThreeStepCoatingContainer">
                    <div className="ThreeStepHeader">
                        <h2> {t("aboutUsPage.aboutUs3Step.title2")}</h2>
                        <p> {t("aboutUsPage.aboutUs3Step.subsubtitle2")}</p>
                    </div>
                    <div className="ThreeStepContent">
                        <div className="ThreeStepImg">
                            <StaticImage src="../../img/aboutus/coating.png" alt="3 Step Coated" />
                        </div>
                        <div className="ThreeStepDesc" style={{ whiteSpace: "pre-wrap" }}>
                            <p>{t("aboutUsPage.aboutUs3Step.content2")}</p>
                            <p>{t("aboutUsPage.aboutUs3Step.content3")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AboutUs3Step;
