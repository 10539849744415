import React from "react";
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from "gatsby";
import "./AboutUsOurBrand.scss";
import mobileImg1 from "../../img/aboutus/mobile1.png";
import mobileImg2 from "../../img/aboutus/mobile2.png";
import mobileImg2thai from "../../img/aboutus/mobile2thai.png";
import BackgroundImage from 'gatsby-background-image';

const AboutUsOurBrandMobile = (props) => {
    
    const { language } = useI18next();
    const { t } = useTranslation();

    const data = useStaticQuery(
        graphql`
            query {
                background: file(relativePath: { eq: "aboutus/missionbg3.png" }) {
                    childImageSharp {
                        fluid(quality: 90, maxWidth: 1920) {
                            ...GatsbyImageSharpFluid_withWebp
                        }
                    }
                }
            }
        `
    );
    const imageData = data.background.childImageSharp.fluid;
    return (
        <div className="aboutusOurBrandMobile" style={{ whiteSpace: "pre-wrap" }}>
            <div className="aboutusMobileHeader">
                <h3>{t("aboutUsPage.ourBrand.title1")}</h3>
                <h2>{t("aboutUsPage.ourBrand.subtitle1")}</h2>
                <h4>{t("aboutUsPage.ourBrand.subsubtitle1")}</h4>
            </div>
            <div className="aboutusMobileContent">
                <div className="aboutusMobileImg1">
                    <img loading="lazy" src={mobileImg1} alt="Our Brand1" />
                </div>
                <div className="aboutusMobileDesc">
                    <p>
                        {t("aboutUsPage.ourBrand.content1").replace(/\n/g, " ")}
                        <b>{t("aboutUsPage.ourBrand.boldContent1").replace(/\n/g, " ")}</b>
                        {t("aboutUsPage.ourBrand.content2").replace(/\n/g, " ")}
                    </p>
                </div>
            </div>
            <BackgroundImage
                Tag="div"
                className={"aboutusMobileOurMission"}
                fluid={imageData}
                backgroundColor=""
            >
            {/* <div className="aboutusMobileOurMission"> */}
                <div className="aboutusMobileOurMissionContent">
                    <div className="aboutusOurMissionImg2">
                        {
                        language !== "th" ? <img loading="lazy" src={mobileImg2} alt="Our Mission 2" /> : <img loading="lazy" src={mobileImg2thai} alt="Our Mission 2" />}
                    </div>
                    <div className="aboutusOurMissionContent">
                        <h2>{t("aboutUsPage.aboutUsOurMission.title1")}</h2>
                        <p>{t("aboutUsPage.aboutUsOurMission.content1")}</p>
                        <p>{t("aboutUsPage.aboutUsOurMission.content2")}</p>
                    </div>
                </div>
            {/* </div> */}
            </BackgroundImage>
        </div>
    );
};

export default AboutUsOurBrandMobile;
